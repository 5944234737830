<template>
  <div class="wrapper main-bar" :class="{ 'main-layout': !isEmpty(image) }">
    <div class="d-flex flex-column align-items-end" :style="{ background: pagebg }">
      <div class="content"></div>
    </div>
    <div class="fixed-main-bar" :style="stylize" :class="{ glass: image }">
      <UserArea />
      <div class="content">
        <div
          :style="{ background: pagebg }"
          class="menu-bg w-100"
          :class="{
            'eliminate-bg': !isAuthenticated || !isEmpty(image),
            'chat-page': isChatPage,
          }"
        >
          <div class="rest">
            <BigButton label="Create" @click="toggleCreate" />
            <div class="items mt-2">
              <router-link to="/myhome">
                <ion-button class="menu-btn w-100">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="action-area d-flex align-items-center">
                      <inline-svg class="mr-2" :src="menuHome" /> Home
                    </div>
                  </div>
                </ion-button>
              </router-link>

              <ion-button class="menu-btn w-100" @click="toggleMyCreations">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="action-area d-flex align-items-center">
                    <inline-svg class="mr-2" :src="creations" /> My Creations
                  </div>
                </div>
              </ion-button>

              <router-link to="/notifications">
                <ion-button class="menu-btn w-100">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="action-area d-flex align-items-center">
                      <inline-svg class="mr-2" :src="notifications" /> Notifications
                    </div>
                    <div v-if="unreadCount" class="has-new">+{{ compactNumber(unreadCount) }}</div>
                  </div>
                </ion-button>
              </router-link>

              <ion-button @click="goToRolechat" :class="{ disabledp: !userHasCharacters }" class="menu-btn w-100">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="action-area d-flex align-items-center">
                    <inline-svg class="mr-2" :src="rolechat" /> Roleplay
                    <i v-if="!userHasCharacters" class="ti-lock disabled-icon position-absolute" />
                  </div>
                </div>
              </ion-button>

              <!-- <router-link to="/feed">
                <ion-button class="menu-btn w-100">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="action-area d-flex align-items-center">
                      <inline-svg class="mr-2" src="/icons/feed.svg" /> Feed
                    </div>
                  </div>
                </ion-button>
              </router-link> -->

              <router-link :to="{ name: 'social-space' }">
                <ion-button class="menu-btn w-100">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="action-area d-flex align-items-center">
                      <inline-svg class="mr-2" :src="socialspace" /> Social Spaces
                    </div>
                    <div v-if="hasUnreadWorldMsgs" class="has-new">+{{ compactNumber(hasUnreadWorldMsgs) }}</div>
                  </div>
                </ion-button>
              </router-link>

              <router-link to="/fanwork">
                <ion-button class="menu-btn w-100">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="action-area d-flex align-items-center">
                      <inline-svg class="mr-2" :src="fanwork" /> My FanWork
                    </div>
                    <div v-if="hasUnopenedFanWork" class="has-new">+{{ compactNumber(hasUnopenedFanWork) }}</div>
                  </div>
                </ion-button>
              </router-link>

              <router-link to="/marketplace">
                <ion-button class="menu-btn w-100">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="action-area d-flex align-items-center">
                      <inline-svg class="mr-2" :src="marketplace" /> Marketplace
                    </div>
                  </div>
                </ion-button>
              </router-link>
            </div>
            <div class="position-relative">
              <router-link to="/shop">
                <div class="freebie" v-if="get(usersFreebie, 'status') === 'closed'">FREE</div>
                <img loading="lazy" class="ch-shop mt-3" src="/ch-shop.svg" />
              </router-link>
            </div>
            <div style="position: relative" v-if="!isUserSubscribed">
              <div class="d-flex align-items-center mx-auto justify-content-center" v-if="discountEndTime">
                <ion-badge class="timer-badge">
                  <div>Welcome Discount!</div>

                  <div><i class="ti-timer mx-1" style="font-size: 12px" /></div>
                  <vue-countdown v-slot="{ days, hours, minutes, seconds }" :time="discountEndTime">
                    <span class="countdown">
                      <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                      ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
                    </span>
                  </vue-countdown></ion-badge
                >
              </div>
              <img
                v-if="featureFlags.pro"
                loading="lazy"
                class="w-100 ch-shop"
                src="/images/ssub2.svg"
                @click="openSubscribeModal($event, 'sidebar-desktop')"
              />
            </div>

            <div class="other mt-3">
              <div class="title">Other stuff:</div>
              <div class="buttons mt-2 d-flex flex-wrap">
                <router-link class="flex-grow-1" to="/my-creations?tab=bookmarks">
                  <ion-button class="other-btn w-100">
                    <inline-svg class="mr-1" src="/icons/bookmark.svg" />
                    My Bookmarks
                  </ion-button>
                </router-link>
                <router-link class="flex-grow-1" to="/my-reactions">
                  <ion-button class="other-btn w-100">
                    <inline-svg class="mr-1" src="/icons/thumbs-up-like.svg" />
                    My Reactions
                  </ion-button>
                </router-link>
                <div class="flex-grow-1 position-relative">
                  <router-link to="/hubert-museum">
                    <ion-button class="other-btn w-100">
                      <inline-svg class="mr-1" src="/icons/love.svg" />
                      Hubert Museum
                    </ion-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BigButton from '@/shared/components/BigButton.vue';
import UserArea from './UserArea.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { compactNumber } from '@/shared/utils/number';
import { featureFlags } from '@/shared/config/feature-flags';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import store from '@/shared/store';
import { uiStore } from '@/shared/pinia-store/ui';
import { openSubscribeModal } from '@/shared/utils/modals';
import { toast } from '@/shared/native';

const { toggleCreate, toggleMyCreations } = uiStore();
const { isCharacterProfile, isCharBackgroundImage } = useCharPageColors();
const goToRolechat = () => {
  if (!userHasCharacters.value) {
    toast.show('Create character first to enable roleplay chats', 'nonative', 'secondary');
    return;
  }

  router.push({ name: 'rolechats' });
};

const { customizeCharacter, currentTemplate } = characterStylizeStore();
const discountEndTime: any = ref(null);
const { isAuthenticated, usersFreebie, userHasCharacters } = authStore();
const { dark } = mainStore();
const router = useRouter();
const { image } = useBackgroundImage();

const menuHome = computed(() => {
  return image.value && dark.value ? '/icons/dark-menu-home.svg' : '/icons/menu-home.svg';
});

const creations = computed(() => {
  return image.value && dark.value ? '/icons/dark-creations.svg' : '/icons/creations.svg';
});

const notifications = computed(() => {
  return image.value && dark.value ? '/icons/dark-menu-bell.svg' : '/icons/menu-bell.svg';
});
const rolechat = computed(() => {
  return image.value && dark.value ? '/icons/dark-rp.svg' : '/icons/rp.svg';
});
const socialspace = computed(() => {
  return image.value && dark.value ? '/icons/dark-social-spaces.svg' : '/icons/social-spaces.svg';
});
const fanwork = computed(() => {
  return image.value && dark.value ? '/icons/dark-menu-fanwork.svg' : '/icons/menu-fanwork.svg';
});
const marketplace = computed(() => {
  return image.value && dark.value ? '/icons/dark-marketplace.svg' : '/icons/marketplace.svg';
});
const routeName = computed(() => {
  try {
    return router.currentRoute.value.name;
  } catch (error) {
    return '';
  }
});

const otherBtnClr = computed(() => {
  return dark.value ? '#fff' : '#214163';
});

const isUserSubscribed = computed(() => {
  const { subscriptionLevel } = authStore();
  return subscriptionLevel.value > 0;
});

const isChatPage = computed(() => {
  return routeName.value === 'social-space-chatroom';
});

const pagebg = computed(() => {
  if (isCharacterProfile.value && !isCharBackgroundImage.value) {
    if (isEmpty(get(customizeCharacter.value, 'template_applied')) && dark.value) {
      return '#010123 !important';
    } else if (isEmpty(get(customizeCharacter.value, 'template_applied'))) {
      return '#fff !important';
    } else {
      return `${get(currentTemplate.value, 'data.theme.backgrounds.page')} !important`;
    }
  }
});

const stylize = computed(() => {
  return !isEmpty(image.value) ? { background: pagebg } : { background: pagebg, left: 0 };
});

const getUnopenedFanWork = () => store.dispatch('FanworksModule/getUnopenedFanWork');

const unreadCount = computed(() => {
  return store.getters['NotificationsModule/unreadCount'];
});

const hasUnreadWorldMsgs = computed(() => {
  return store.getters['WorldsModule/hasUnreadWorldMsgs'];
});

const hasUnopenedFanWork = computed(() => {
  return store.getters['FanworksModule/hasUnopenedFanWork'];
});

const textClr = computed(() => {
  return !dark.value ? '#214163' : '';
});

onMounted(() => {
  getUnopenedFanWork();
  const { discountEndsAt } = useWelcomeDiscountTime();
  discountEndTime.value = discountEndsAt();
  setTimeout(() => {
    discountEndTime.value = 0;
  }, discountEndTime.value);
});
</script>

<style scoped lang="sass">
.main-layout
  min-width: calc(min(100vw, 1260px) * 20 / 100)
  max-width: calc(min(100vw, 1260px) * 20 / 100)
.disabled-icon
  z-index: 1
  color: #FFF
  right: calc(50% - 80px)
  background: rgba(0,0,0,0.5)
  padding: 5px
  border-radius: 20px
.freebie
  font-size: 12px
  display: flex
  z-index: 2
  color: white
  background: linear-gradient(90deg, #6ADF32 0%, rgba(56, 226, 83, 0) 100%), #1B856B
  border: 2px solid #214163
  border-radius: 5px
  position: absolute
  top: 12px
  left: -1px
  width: 40px
  justify-content: center
  align-items: center
  text-align: center
.timer-badge
  padding: 6px 8px 6px 8px
  display: flex
  align-items: center
  background: linear-gradient(to right, #FF004D, #EE4035)
  justify-content: center
  font-size: 14px
  border-radius: 7px
  position: absolute
  bottom: -10px

.fixed-main-bar
  position: sticky
  //width: calc((100vw - 780px) * 0.5)
  z-index: 1
  box-shadow: 12px 0px 10px 1px rgba(35, 14, 51, 0.1)
  display: flex
  align-items: flex-end
  height: calc(100vh - var(--default-nav-bar-height))
  flex-direction: column
  .content
    overflow: auto
    margin-right: 2px
    min-width: calc(min(100vw, 1500px) * 24/100)
    max-width: calc(min(100vw, 1500px) * 24/100)
  .glass
    background: rgba(255, 255, 255, 0.61)
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
    backdrop-filter: blur(4px)
    -webkit-backdrop-filter: blur(4px)
    min-width: 100vw
.dark .fixed-main-bar.glass
  background: rgba(0, 0, 0, 0.55)
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
  backdrop-filter: blur(4px)
  -webkit-backdrop-filter: blur(4px)

.soon
  font-size: 9px
  color: #fff
  border-radius: 10px
  padding: 2px 4px
  position: absolute
  background: rgba(255, 56, 100, 1)
  z-index: 1
  right: -5px
.has-new
  height: 16px
  border-radius: 8px
  color: #FFF
  background: rgba(255, 56, 100, 1)
  display: flex
  align-items: center
  justify-content: center
  font-weight: bold
  font-size: 10px
  padding: 0 5px
.menu-btn
  --background: transparent
  --padding-start: 5px
  --padding-end: 5px
  &.disabledp
    opacity: 0.7
  &:hover
    opacity: 0.7 !important
  .action-area
    color: v-bind(textClr)
    font-weight: bold
.dark .menu-btn .action-area
  color: v-bind(textClr) !important
.other
  .title
    color: #B268E5 !important
    font-weight: bold
  .buttons
    grid-gap: 4px
  .other-btn
    border: 1px solid v-bind(otherBtnClr)
    border-radius: 6px
    box-shadow: 0px 0px 4px 0px rgba(33, 65, 99, 0.4)
    --background: transparent
    color: v-bind(otherBtnClr)
    height: 24px
    font-size: 13px
.dark .other .other-btn
  color: v-bind(otherBtnClr) !important
  border: 1px solid v-bind(otherBtnClr) !important
.ch-shop
  height: auto
  width: 100%
  opacity: 1.0
  &:hover
    opacity: 0.7
    cursor: pointer

.rest
  padding: 24px
.menu-backdrop
  position: absolute
  width: 100vw
  height: 100%
  right: 0
  left: 0
  z-index: 111
  background-color: rgba(0,0,0,0.2)
  cursor: pointer
.wrapper
  //min-width: 64px
  //box-shadow: -1px -2px 11px 1px rgba(35,14,51,0.19)

.menu-bg-white
  background: #FFF
  width: 100%
  height: 100%
  &.eliminate-bg
    background: transparent !important
.menu-bg
  width: 100%
  transition: 300ms ease all
  flex-grow: 1
  background: #FFF
  position: relative
  justify-content: flex-end
  z-index: 111
  scrollbar-width: none
  .menu-lg
    padding-right: 10px
  &.eliminate-bg
    background: transparent !important
  &.menu-collapsed
    padding: 40px 10px !important
    position: relative !important

@media(max-width: 1320px)
  .menu-shadow
    width: 64px
    margin-right: 10px

@media(max-width: 570px)
  .wrapper
    width: 0 !important
    min-width: 0 !important
  .menu-bg
    top: 40px !important
    height: calc(100vh - var(--default-nav-bar-height) - 50px) !important
    &.chat-page
      top: 90px !important
      height: 110% !important
    .menu-lg
      top: unset !important
      position: relative !important
    &.menu-collapsed
      width: 0 !important
      padding: 40px 0 !important
.menu-lg
  height: 100%
  scrollbar-width: none
.main-bar
  overflow-y: auto !important
  background: #FFF
  position: relative
  .content
    min-width: calc(min(100vw, 1240px) * 20/100)
    max-width: calc(min(100vw, 1240px) * 20/100)
</style>
