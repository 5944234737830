<template>
  <div class="creations-bar" :class="{ 'eliminate-bg': !isEmpty(image) }">
    <div
      class="wrapper px-3 d-flex align-items-center justify-content-between w-100"
      :class="{ 'hide-wrapper': !isVisible }"
    >
      <div class="title-content d-flex justify-content-between w-100">
        <div class="action-area d-flex align-items-center" :class="{ 'flex-column mx-auto': !isVisible }">
          <div
            v-if="!isVisible"
            class="p-1"
            style="border: 2px solid rgba(0, 180, 197, 1) !important; border-radius: 8px; margin-top: 8px"
          >
            <inline-svg
              class="arrow-svg clickable-item-hov"
              @click="toggleCreations"
              src="/icons/arrow-back-outline.svg"
            />
          </div>
          <div v-if="loader && !isVisible">
            <ion-skeleton-text animated class="my-1" style="width: 35px; height: 32px" />
          </div>

          <inline-svg
            src="/icons/creations.svg"
            v-else
            :class="{ 'folder-svg clickable-item-hov ': !isVisible, 'mr-2': isVisible }"
            @click.prevent="openFolderModal"
          />
          {{ isVisible ? 'My Creations' : '' }}
        </div>
        <div>
          <ion-button fill="outline" class="toggle-btn hide" @click="toggleCreations" v-if="isVisible"
            ><inline-svg class="mr-1 icon" height="15px" src="/icons/arrow-forward-outline.svg" /> Hide</ion-button
          >
        </div>
      </div>
    </div>
    <div class="d-flex flex-column h-100" :class="{ content: isVisible, 'hidden-content': !isVisible }">
      <Creations
        :is-visible="isVisible"
        class="creations-visible"
        :ringWidth="45"
        :ringTopAlignment="-22"
        :ringLeftAlignment="-5"
        :folders="folders"
        :selectedHideFolder="selectedFolder"
        @selected="folderSelection"
      />

      <div class="flex-grow-1" />
      <ImageStorageSpace
        class="img-stg-space"
        upgrade-tag-bg-color="#fff"
        v-if="featureFlags.storage && userStoragePercentage >= 90"
        :show-title="true"
      />
      <FooterButtons :isVisible="isVisible" class="mt-3 footer-btns" />
      <FolderSectionModal
        :is-open="isOpenFolderSection"
        :folders="folders"
        @close="isOpenFolderSection = false"
        @submit="folderSelection"
        :folder="selectedFolder"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Creations from './Creations.vue';
import ImageStorageSpace from './ImageStorageSpace.vue';
import FooterButtons from './FooterButtons.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import { authStore } from '@/shared/pinia-store/auth';
import FolderSectionModal from '@/shared/modals/FolderSectionModal.vue';
import { getMyCreationsBarFolders, getProfileFolders } from '@/shared/actions/collections';
import { Collection, Paging } from '@/shared/types/static-types';
import { mainStore } from '../pinia-store/main';

const { userStoragePercentage, user } = authStore();
const props = defineProps({
  isVisible: { type: Boolean },
});
const isVisible = toRef(props, 'isVisible');
const emits = defineEmits(['toggle']);
const foldersPage = ref(1);
const folders = ref<Collection[]>([]);
const foldersPaging = ref<Paging>();
const loader = ref(true);
const selectedFolder = ref('');
const { dark } = mainStore();
const isOpenFolderSection = ref(false);
const { bg: b, isCharacterProfile } = useCharPageColors();
const { image } = useBackgroundImage();
const bg = computed(() => b.value.replace(' !important', ''));
const toggleCreations = () => {
  emits('toggle');
};

const route = useRoute();
const openFolderModal = async () => {
  isOpenFolderSection.value = true;
};

const layoutBg = computed(() => {
  const defaultColor = dark.value ? '#010123' : '#FFF';
  return isCharacterProfile.value ? bg.value : defaultColor;
});

const folderSelection = (value: any) => {
  selectedFolder.value = value;
  isOpenFolderSection.value = false;
};

const getHeight = computed(() => {
  return isVisible.value ? '50px' : '95px';
});

const loadFolders = async () => {
  if (!user.value?.username) return;

  const res = await getMyCreationsBarFolders(user.value?.username, foldersPage.value);
  const paginatedRes = res.value;

  if (!paginatedRes) return;

  const { results, ...rest } = paginatedRes;

  foldersPaging.value = rest;
  folders.value = [...folders.value, ...results];
  loader.value = false;
};

onMounted(async () => {
  await loadFolders();
});
</script>

<style scoped lang="sass">
.img-stg-space
  &.hide
    position: absolute
    bottom: 80px
.footer-btns
  &.hide
    position: absolute
    bottom: 10px
    right: 0
    left: 0
    max-width: calc(min(100vw, 1500px)* 24/100)
.creations-visible
  max-height: auto
  transition: 300ms ease all
  &.hide
    max-height: 0px !important
    transition: 300ms ease all
    overflow: hidden
.hide
  --ion-color-primary: rgba(0, 180, 197, 1) !important
.icon
  min-width: 15px
  width: 15px
.toggle-btn
  width: 74px
  height: 24px
  font-size: 12px
  text-transform: uppercase !important
.title-content
  max-width: calc(min(100vw, 1500px) * 24.5/100)
.folder-svg
  width: 29px
  height: 29px
  margin-top:17px
.arrow-svg
  color: rgba(0, 180, 197, 1) !important
  width: 25px
  height: 25px

.action-area
  color: rgba(33, 65, 99, 1)
  font-weight: bold
.hide-wrapper
  min-width: 85px !important
  max-width: 85px !important
.wrapper
  width: 100%
  min-width: calc(min(100vw, 1455px)* 20 / 100)
  max-width: calc(min(100vw, 1455px)* 20 / 100)
  height: v-bind(getHeight)
  z-index: 1111
  max-height: v-bind(getHeight)
  @media(max-width:1425px) and (min-width:1350px)
    min-width: calc(min(100vw, 1500px)* 21 / 100)
    max-width: calc(min(100vw, 1500px)* 21 / 100)
  @media(max-width:1350px) and (min-width:1305px)
    min-width: calc(min(100vw, 1500px)* 22 / 100)
    max-width: calc(min(100vw, 1500px)* 22 / 100)
  @media(max-width:1305px) and (min-width:1290px)
    min-width: calc(min(100vw, 1500px)* 21 / 100)
    max-width: calc(min(100vw, 1500px)* 21 / 100)
  @media(max-width:1235px)
    min-width: calc(min(100vw, 1455px)* 21 / 100)
    max-width: calc(min(100vw, 1455px)* 21 / 100)
  @media(max-width:1200px) and (min-width:1184px)
    min-width: calc(min(100vw, 1455px)* 22 / 100)
    max-width: calc(min(100vw, 1455px)* 22 / 100)
.dark .creations-bar
  background: v-bind(layoutBg) !important
  &.eliminate-bg
    background: transparent !important

.creations-bar
  //width: calc((100vw - 650px) * 0.5)
  min-height: calc(100vh - var(--default-nav-bar-height))
  background: v-bind(layoutBg) !important
  z-index: 11
  overflow-y: auto
  overflow-x: hidden
  &.eliminate-bg
    background: transparent !important
  .hidden-content
    min-width: calc(min(100vw, 1240px)* 8 / 100)
    max-width: calc(min(100vw, 1240px)* 8 / 100)
  .content
    max-width: calc(min(100vw, 1240px)* 23 / 100)
    min-width: calc(min(100vw, 1240px)* 23 / 100)
    min-height: calc(100vh - 50px - var(--default-nav-bar-height))
    @media(max-width:1320px)
      min-width: calc(min(100vw, 1220px)* 21 / 100)
      max-width: calc(min(100vw, 1220px)* 21 / 100)
    @media(max-width:1306px) and (min-width: 1280px)
      min-width: calc(min(100vw, 1240px)* 23 / 100) !important
      max-width: calc(min(100vw, 1240px)* 23 / 100) !important
    @media(max-width:1280px) and (min-width: 1233px)
      min-width: calc(min(100vw, 1240px)* 20 / 100) !important
      max-width: calc(min(100vw, 1240px)* 20 / 100) !important
</style>
