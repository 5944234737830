<template>
  <div
    class="user-area-wrap wrapper flex-grow-1 d-flex justify-content-end"
    :style="!isEmpty(image) && dark ? 'background: none !important' : ''"
  >
    <div class="user-area d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <ProfileIcon :width="32" :height="32" id="o-pfp-1" @click="profile" />
        <div
          :class="{
            'text-color-username': usernameColor && !usernameColor.startsWith('#'),
            'bold-username': usernameColor,
          }"
          :style="
            !usernameColor
              ? ''
              : usernameColor.startsWith('#')
              ? `color: ${usernameColor} !important`
              : `background-image: ${usernameColor} !important`
          "
          class="ml-2 profile-name d-flex align-items-center"
          @click="profile"
        >
          <div class="mr-1">{{ username }}</div>
          <ProfileBadge :badges="badges" :show-small-badge="true" />
        </div>
      </div>
      <div class="d-flex">
        <ion-button color="clear" class="inline-button mr-n2" @click="dropdownToggle">
          <inline-svg src="/icons/cog.svg" />
        </ion-button>
        <ion-menu-button v-if="!isDesktopSize" mode="ios" color="clear" class="inline-button mr-n2 ml-2">
          <i class="ti-close" />
        </ion-menu-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import HomeProfilePopover from './nav-bar/popovers/home-profile-popover.vue';
import ProfileIcon from './ProfileIcon.vue';
import { popovers } from '@/shared/native/popovers';
import { authStore } from '@/shared/pinia-store/auth';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { mainStore } from '../pinia-store/main';

const { user } = authStore();
const { image } = useBackgroundImage();
const { dark } = mainStore();
const badges = computed(() => {
  return get(user.value.customize_profile, 'badges') || [];
});

const profile = async () => {
  const router = useRouter();

  await router.push({ name: 'profile', params: { username: username.value } });
};

const { isDesktopSize } = useWindowSize();

const username = computed(() => {
  return user.value?.username || '';
});

const usernameColor = computed(() => {
  return user.value?.customize_profile?.username_color || '';
});

const dropdownToggle = (ev: MouseEvent) => {
  popovers.open(ev, HomeProfilePopover, {}, 'profile-popover');
};
</script>

<style lang="sass" scoped>
.user-area-wrap
  min-height: 50px
.wrapper
  //box-shadow: -20px -7px 4px 10px rgba(35,14,51,0.1) !important
  width: 100%
  height: 50px
  z-index: 1111
  max-height: 50px
ion-menu-button
  --padding-start: 5px
  --padding-end: 5px
  --width: 30px
  width: 30px
  height: 30px
.user-area
  height: 50px
  padding: 9px 24px
  width: 100%
  max-width: calc(min(100vw, 1240px) * 20/100)
  min-width: calc(min(100vw, 1240px) * 20/100)
.profile-name
  color: var(--ion-color-primary)
  font-size: 14px
  font-weight: bold
  cursor: pointer
</style>
